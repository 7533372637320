import React from "react";
import style from "../../../styles/sections/Claim.module.scss";
import Button from "../../UI/Buttons/Button";

const Claim = () => {
  return (
    <div className={style.claimWrap}>
      <div className={`${style.claimContainer} container-xl`}>
        <div className={style.content}>
          <div className={style.info}>
            <h3>Claim Your Care Home</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quis
              pretium nunc. Lorem ipsum dolor sit amet, consectetur adipiscing
              elit. Sed quis pretium nunc.
            </p>
          </div>
          <div className={style.action}>
            <Button
              variant="light"
              label="Claim Now"
              fullWidth={true}
              isLink={true}
              redirectTo={`/signup`}
              size="xl"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Claim;
