import style from "../../../styles/ui/Buttons.module.scss";
import Link from "next/link";
const Button = (props) => {
  const renderClassName = () => {
    let className = `${style.button}`;

    if (props?.variant?.length > 0) {
      className = `${className} ${style[props.variant]}`;
    }
    if (props?.fullWidth) {
      className = `${className} ${style.fullWidth}`;
    }
    if (props?.halfWidth) {
      className = `${className} ${style.halfWidth}`;
    }
    if (props?.fixedWidth) {
      className = `${className} ${style.fixedWidth}`;
    }
    if (props?.disabled) {
      className = `${className} ${style.disabled}`;
    }
    if (props?.size?.length > 0) {
      className = `${className} ${style[[props.size]]}`;
    }
    if (props?.regular) {
      className = `${className} ${style.regular}`;
    }
    if (props?.hasIcon || props.hasSVG) {
      className = `${className} ${style.flex}`;
    }
    return className;
  };

  return (
    <>
      {props?.isLink ? (
        <Link href={props.redirectTo} target="_blank" style={{ width: "100%" }}>
          <button
            type={props?.type ? props.type : "text"}
            onClick={() =>
              typeof props?.onClick === "function"
                ? props?.disabled !== true && props.onClick()
                : {}
            }
            className={renderClassName()}
          >
            {props?.hasIcon && (
              <>
                <img src={props.icon} />
              </>
            )}
            {props?.hasSVG && <>{props.icon}</>}
            {props.label}
          </button>
        </Link>
      ) : (
        <button
          onClick={() =>
            typeof props?.onClick === "function"
              ? props?.disabled !== true && props.onClick()
              : {}
          }
          className={renderClassName()}
        >
          {props?.hasIcon && (
            <>
              <img src={props.icon} />
            </>
          )}
          {props?.hasSVG && <>{props.icon}</>}
          {props.label}
        </button>
      )}
    </>
  );
};

export default Button;
